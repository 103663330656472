import { Component, EventEmitter, Input, Output } from '@angular/core';
import { goBackGeneric } from '@app/utils/utils';

@Component({
  selector: 'app-white-header',
  templateUrl: './white-header.component.html',
  styleUrls: ['./white-header.component.scss'],
})
export class WhiteHeaderComponent {
  @Input() canGoBack: boolean = true;
  @Input() canLogout: boolean = false;
  @Input() canGoToProfile: boolean = true;
  @Input() loggedInUserId: number = null;
  @Input() userId: number = null;
  @Output() onLogout: EventEmitter<any> = new EventEmitter();
  @Output() onGoBack: EventEmitter<any> = new EventEmitter();

  goBack() {
    if (this.onGoBack.observers.length > 0) {
      this.onGoBack.emit([]);
    } else {
      goBackGeneric();
    }
  }

  callLogout() {
    if (this.onLogout.observers.length > 0) {
      this.onLogout.emit([]);
    }
  }

}
