import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject, from, defer } from 'rxjs';
import { environment } from '@environments/environment';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
  // 1 means can replay only the last value
  isInitialized: ReplaySubject<boolean> = new ReplaySubject(1);

  unloggedHttpOptions = {
    headers: new HttpHeaders({
      'Authorization': ""
    })
  };

  constructor(
    private http: HttpClient,
    private native: HTTP,
    private platform: Platform,
    private router: Router,
    private userService: UserService,
  ) {
    this.loadToken();
  }

  async loadToken() {
    const token = await localStorage.getItem('token');
    if (token) {
      this.token = token;
      this.test_connection().subscribe(
        (_) => {
          this.isAuthenticated.next(true);
          this.isInitialized.next(true);
        },
        (_) => {
          this.isAuthenticated.next(false);
          this.isInitialized.next(true);
        },
        () => {
          console.log('Complete');
        }
      );
    } else {
      this.isAuthenticated.next(false);
      this.isInitialized.next(true);
    }
  }

  register(credentials: {
    email: string;
    password: string;
    phone: string;
    firstName: string;
    lastName: string;
    height: number;
    weight: number;
    address: string;
    waist: number;
    gender: number;
  }): Observable<any> {
    var user = {
      email: credentials.email,
      password: credentials.password,
    };
    var patient = {
      phone: credentials.phone,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      height: credentials.height,
      weight: credentials.weight,
      address: credentials.address,
      waist: credentials.waist,
      gender: credentials.gender,
    };

    var dataRegister = {
      user: user,
      patient: patient,
    };

    console.log(dataRegister);
    return this.http.post(
      `${environment.apiUrl}/patient/register`,
      dataRegister
    );
  }

  login(credentials: {country_code: string, phone: any, password: any}): Observable<any> {
    return this.http
      .post(
        `${environment.apiUrl}/auth/login/`,
        credentials,
        this.unloggedHttpOptions
      )
      .pipe(
        tap((data) => {
          this.isAuthenticated.next(true);
          localStorage.setItem('user_id', data.id);
          localStorage.setItem('refresh_token', data.refresh);
          localStorage.setItem('token', data.access);
          localStorage.setItem('role', data.role_type);
        })
      );
  }

  logout(callBackend: boolean = true): Promise<[void, void, void, void, Object]> {
    this.isAuthenticated.next(false);
    const refresh_token = localStorage.getItem('refresh_token');
    let http_logout = null;
    if (callBackend) {
      http_logout = this.http
        .post(`${environment.apiUrl}/auth/logout/`, { refresh_token: refresh_token })
        .toPromise();
    }
    return Promise.all([
      localStorage.removeItem('refresh_token'),
      localStorage.removeItem('user_id'),
      localStorage.removeItem('role'),
      localStorage.removeItem('token'),
      http_logout,
    ]);
  }

  test_connection() {
    return this.http.get(`${environment.apiUrl}/auth/test/`);
  }
}
