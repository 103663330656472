import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GoogleApiService {
    isMapsLoaded: boolean = false;
    options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
    };

    constructor() {}
  
    setMapsLoaded(status: boolean) {
      this.isMapsLoaded = status;
    }

    getMapsOptions() {
      return this.options;
    }
}