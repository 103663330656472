import { createAction, props } from '@ngrx/store';
import { HttpResponse } from '@angular/common/http';

export const loginRequest = createAction(
  '[Auth] Login Request',
  props<{ cred: { email: string; password: string } }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ loginSuccessResponse: any }>()
);

export const loginFailure = createAction(
  '[Auth] Logout',
  props<{ error: string }>()
);

export const logout = createAction('[Auth] Logout');

export const test = createAction('[Auth] Logout');
