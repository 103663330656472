import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private networkLoader: HTMLIonLoadingElement | null = null;

  constructor(private loadingController: LoadingController) {}

  async showNetworkLoader() {
    if (!this.networkLoader) {
      this.networkLoader = await this.loadingController.create();
      await this.networkLoader.present();
    }
  }

  async hideNetworkLoader() {
    await this.networkLoader?.dismiss();
    this.networkLoader = null;
  }

  getNetworkLoader() {
    return this.networkLoader;
  }
}
