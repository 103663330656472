import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private translateService: TranslateService) {}

  selectedLanguage = "en";

  languagesList = [
    {"lang": "fr", "label": "🇫🇷 Français"},
    {"lang": "en", "label": "🇬🇧 English"},
    {"lang": "es", "label": "🇪🇸 Español"},
    {"lang": "it", "label": "🇮🇹 Italiano"},
    {"lang": "de", "label": "🇩🇪 Deutsch"},
    {"lang": "nl", "label": "🇳🇱 Nederlands"}
  ]

  getLanguage() {
    const formattedNavigatorLanguage = navigator.language.split("-")[0];
    const defaultLanguage = this.languagesList.some((element) => element.lang === formattedNavigatorLanguage) ? formattedNavigatorLanguage : "en";
    const savedLanguage = localStorage.getItem('language') || defaultLanguage;
  
    this.selectedLanguage = savedLanguage;
    this.updateLanguage(savedLanguage);
  }
  
  changeLanguage(selectedLanguage: string) {
    localStorage.setItem('language', selectedLanguage);
    this.updateLanguage(selectedLanguage);
  }
  
  updateLanguage(language: string) {
    this.translateService.use(language);
  }

  getSelectedLanguage() {
    return this.selectedLanguage;
  }

  getLanguagesList() {
    return this.languagesList;
  }
}
