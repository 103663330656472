import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[format-numbers]'
})
export class FormatNumbersDirective {
  @Input('format-numbers') params: string;

  constructor(
    private el: ElementRef,
    private control: NgControl
  ) { }

  private formatString(event: InputEvent): string {
    let input = event.target as HTMLInputElement;

    let trimmedPattern = this.params.replace(/\s+/g, '');

    let trimmed = input.value.replace(/\D+/g, '');
    trimmed = trimmed.substring((trimmed.charAt(0) === "0" && trimmed.length > 1 ? 1 : 0), (trimmed.charAt(0) === "0" ? trimmedPattern.length + 1 : trimmedPattern.length));

    let finalString = "";
    let i = 0;
    let y = 0;
    for (i = 0; i < this.params.length && y < trimmed.length; i += 1) {
      if (this.params[i] == 'X') {
        finalString += trimmed[y];
        y += 1;
      } else {
        finalString += this.params[i];
      }
    }
    if (i < this.params.length && this.params[i] != 'X' && (event as InputEvent).inputType != "deleteContentBackward") {
      finalString += this.params[i];
    }
    return finalString;
  }

  private saveString(result: string) {
    this.control.viewToModelUpdate(result);
    this.control.valueAccessor.writeValue(result);
    this.control.control.setValue(result);
  }

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    const result = this.formatString(event);
    this.saveString(result);
  }
}