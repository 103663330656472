import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpConfigInterceptor } from './httpConfig.interceptor';

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from './create-translate-loader';
import { ProfileService } from './services/profile.service';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './state/auth/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './state/auth/auth.effects';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';

import { SharedModule } from './shared-module/shared-module.module';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEnGb from '@angular/common/locales/en-GB';
import localIt from '@angular/common/locales/it';
import localEs from '@angular/common/locales/es';
import localDe from '@angular/common/locales/de';
import localNl from '@angular/common/locales/nl';

const locales = [
  { locale: 'fr-FR', data: localeFr },
  { locale: 'en-GB', data: localeEnGb },
  { locale: 'it-IT', data: localIt },
  { locale: 'es-ES', data: localEs },
  { locale: 'de-DE', data: localDe },
  { locale: 'nl-NL', data: localNl },
];
locales.forEach(({ data, locale }) => registerLocaleData(data, locale));

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    GooglePlaceModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({ auth: authReducer }),
    EffectsModule.forRoot([AuthEffects]),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    HTTP,
    ProfileService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
