import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private router: Router,
  ) {}

  async presentAlert(header: string, subHeader: string, message: string, buttons: any[] = ['OK']) {
    if (await this.needToRelog(message)) {
      header = await this.translateService.get('info').toPromise();
      buttons = [{
        text: 'OK',
        handler: () => {
          this.router.navigateByUrl('/login', { replaceUrl: true })
        }
      }];
    }
    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons,
    });
    await alert.present();
  }

  async needToRelog(message: string): Promise<boolean> {
    const sessionExpiredTranslation = await this.translateService.get('backend.SESSION_EXPIRED').toPromise();
    if (message === sessionExpiredTranslation) {
      return true
    }
    return false;
  }
}
