import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { Browser } from '@capacitor/browser';
import { AppUpdateService } from './app-update.service';
import { LoaderService } from './loader.service';
import { AuthenticationService } from './authentification.service';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private alert: HTMLIonAlertElement | null;
  private sessionExpiredTranslation: string | null = null;

  constructor(
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private router: Router,
    private appUpdateService: AppUpdateService,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
  ) {}

  async presentAlert(header: string, subHeader: string, message: string, buttons: any[] = ['OK'], backdropDismiss: boolean = true) {
    if (message === `backend.Authentication credentials were not provided.` || message === `backend.Given token not valid for any token type`) {
      if (this.authenticationService.isAuthenticated.getValue()) {
        this.authenticationService.logout(false);
      }
      this.showDeconnectedAlert();
      return;
    }
    if (await this.needToRelog(message)) {
      header = await this.translateService.get('info').toPromise();
      buttons = [{
        text: 'OK',
        handler: () => {
          this.router.navigateByUrl('/login', { replaceUrl: true })
        }
      }];
    }
    this.alert = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons,
      backdropDismiss: backdropDismiss,
    });
    await this.alert.present();
  }

  async dismissAlert() {
    this.alert?.dismiss();
    this.alert = null;
  }

  async needToRelog(message: string): Promise<boolean> {
    if (!navigator.onLine) return false;
    if (!this.sessionExpiredTranslation) {
      this.sessionExpiredTranslation = await this.translateService.get('backend.SESSION_EXPIRED').toPromise();
    }
    return message === this.sessionExpiredTranslation;
  }

  async showUpdateAlert(selectedLanguage, initialUrl) {
    await this.dismissAlert();
    const trads = this.tradsForNeedUpdateAlert[selectedLanguage];
    await this.presentAlert(
      trads['title'],
      '',
      trads['info'],
      [
        {
          text: trads['go_web'],
          role: 'cancel',
          handler: async () => {
            const redirectUrl = initialUrl ? `${environment.baseUrl}${initialUrl}` : environment.baseUrl;
            await Browser.open({ url: redirectUrl });
            setTimeout(() => this.showUpdateAlert(selectedLanguage, initialUrl), 1000);
          }
        },
        {
          text: trads['update_app'],
          handler: async () => {
            await this.appUpdateService.openAppStore();
            setTimeout(() => this.showUpdateAlert(selectedLanguage, initialUrl), 1000);
          }
        },
      ],
      false
    );
  }

  async showNoNetworkAlert(selectedLanguage) {
    const trads = this.tradsForNoNetworkAlert[selectedLanguage];
    await this.presentAlert(
      trads['title'],
      '',
      trads['check_connection'],
      [
        {
          text: trads['button'],
          handler: async () => {
            if (navigator.onLine) { return; }
            await this.loaderService.showNetworkLoader();
            setTimeout(async () => {
              if (!navigator.onLine) {
                await this.loaderService.hideNetworkLoader();
                this.showNoNetworkAlert(selectedLanguage);
              }
            }, 2000);
          }
        }
      ],
      false
    );
  }

  async showDeconnectedAlert() {
    this.presentAlert(
      this.translateService.instant('info'),
      '',
      this.translateService.instant('logged_out'),
      [{
        text: 'OK',
        handler: () => {
          this.router.navigateByUrl('/login', { replaceUrl: true })
        }
      }],
      false
    )
  }

  private tradsForNeedUpdateAlert = {
    fr: {
      "title": "Mise à jour disponible",
      "info": "Une nouvelle version de l'application est disponible, veuillez la télécharger.",
      "go_web": "Continuer sur la version Web",
      "update_app": "Mettre à jour",
    },
    en: {
      "title": "Update Available",
      "info": "A new version of the app is available, please download it.",
      "go_web": "Continue on the Web version",
      "update_app": "Update",
    },
    es:{
      "title": "Actualización disponible",
      "info": "Una nueva versión de la aplicación está disponible, por favor descárguela.",
      "go_web": "Continuar en la versión web",
      "update_app": "Actualizar",
    },
    it:{
      "title": "Aggiornamento disponibile",
      "info": "È disponibile una nuova versione dell'app, si prega di scaricarla.",
      "go_web": "Continua sulla versione web",
      "update_app": "Aggiorna",
    },
    de:{
      "title": "Update verfügbar",
      "info": "Eine neue Version der App ist verfügbar, bitte laden Sie sie herunter.",
      "go_web": "Weiter zur Web-Version",
      "update_app": "Aktualisieren",
    },
    nl:{
      "title": "Update beschikbaar",
      "info": "Een nieuwe versie van de app is beschikbaar, download deze alstublieft.",
      "go_web": "Doorgaan met de webversie",
      "update_app": "Bijwerken",
    }
  }

  private tradsForNoNetworkAlert = {
    fr: {
        "title": "Pas de connexion réseau",
        "check_connection": "Veuillez vérifier votre connexion internet.",
        "button": "Réessayer",
    },
    en: {
        "title": "No Network Connection",
        "check_connection": "Please check your internet connection.",
        "button": "Retry"
    },
    es:{
        "title": "Sin conexión de red",
        "check_connection": "Por favor, verifique su conexión a internet.",
        "button": "Reintentar"
    },
    it:{
        "title": "Nessuna connessione di rete",
        "check_connection": "Si prega di controllare la connessione a internet.",
        "button": "Riprova"
    },
    de:{
        "title": "Keine Netzwerkverbindung",
        "check_connection": "Bitte überprüfen Sie Ihre Internetverbindung.",
        "button": "Erneut versuchen"
    },
    nl:{
        "title": "Geen netwerkverbinding",
        "check_connection": "Controleer uw internetverbinding.",
        "button": "Opnieuw proberen"
    }
  };
}
