import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Network, ConnectionStatus } from '@capacitor/network';
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from './services/language.service';
import { MenuController } from '@ionic/angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
// For local dev, @environments/google-api is in cicd-tootlkit at conf/local/hippy-public-application/google-api.ts
import { GoogleMapsApiUrl } from '@environments/google-api';
import { GoogleApiService } from './services/google-api.service';
import { AppUpdateService } from './services/app-update.service';
import { AlertService } from './services/alert.service';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import { LoaderService } from './services/loader.service';
import { AuthenticationService } from './services/authentification.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  private initialUrl: string | null = null;
  private updateRequired: boolean = false;
  private previousNetworkStatus: ConnectionStatus | null = null;

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private menuController: MenuController,
    private zone: NgZone,
    private router: Router,
    private googleApiService: GoogleApiService,
    private appUpdateService: AppUpdateService,
    private alertService: AlertService,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
  ) {
    this.setupDeepLinkRouting();
    this.translate.setDefaultLang('en');
    this.languageService.getLanguage();
    if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
      this.setupNetworkListeners();
    }
  }

  ngOnInit() {
    this.menuController.enable(false);
    this.loadGoogleMapsApi();
    this.addKeyboardListeners();
    // if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
    //   this.checkForAppUpdate();
    // }
    if (Capacitor.getPlatform() === 'android') {
      this.checkForAppUpdate();
    }
  }

  ngOnDestroy() {
    this.removeKeyboardListeners();
  }

  setupDeepLinkRouting() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(async () => {
        let slug: string | undefined;
        if (event.url.includes(".com")) {
          slug = event.url.split(".com").pop();
        } else if (event.url.includes(":8100")) {
          slug = event.url.split(":8100").pop();
        }
        if (slug) {
          this.initialUrl = slug;
          this.updateRequired = await this.checkIfUpdateIsRequired();
          if (!this.updateRequired) {
            const actualStatus = await Network.getStatus();
            if (actualStatus?.connected) {
              this.router.navigateByUrl(slug);
            } else {
              this.router.navigateByUrl('/shell');
              this.showNoNetworkAlert();
            }
          } else {
            console.info("Update required")
            this.showUpdateAlert();
          }
        }
      });
    });

    App.addListener("resume", () => {
      this.zone.run(async () => {
        const status = await Network.getStatus();
        this.previousNetworkStatus = status;
        if (!status.connected) {
          this.router.navigateByUrl('/shell');
          this.showNoNetworkAlert();
        }
      });
    });
  }

  async setupNetworkListeners() {
    Network.addListener('networkStatusChange', async (status) => {
      this.zone.run(async () => {
        console.info('Changing network state:', JSON.stringify(status, null, 2));

        if (this.previousNetworkStatus?.connected !== status.connected) {
          if (status.connected) {
            await this.languageService.reloadTranslations();
            await this.authenticationService.loadToken();
            await this.loaderService.hideNetworkLoader();
            await this.alertService.dismissAlert();
            const user_id = localStorage.getItem('user_id')
            this.router.navigateByUrl(`/${user_id}/user-devices/${user_id}`, { replaceUrl: true });
          } else if (!status.connected) {
            this.router.navigateByUrl('/shell');
            this.showNoNetworkAlert();
          }
        }
        this.previousNetworkStatus = status;
      });
    });

    this.zone.run(async () => {
      this.previousNetworkStatus = await Network.getStatus();
      if (!this.previousNetworkStatus.connected) {
        this.router.navigateByUrl('/shell');
        this.showNoNetworkAlert();
      }
    });
  }

  private async checkIfUpdateIsRequired() {
    try {
      if (Capacitor.getPlatform() === 'android') {
        if (environment.baseUrl.startsWith('http://')) {
          return false; // local env -> no update to do
        }
        const currentAppVersion = await this.appUpdateService.getCurrentAppVersion()
        const availableAppVersion = await this.appUpdateService.getAvailableAppVersion();
        if (availableAppVersion && availableAppVersion !== currentAppVersion) {
          console.info("New available app version: ", availableAppVersion)
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error while checking updates: ", error);
      return true;
    }
  }

  private async checkForAppUpdate() {
    const isUpdateRequired = await this.checkIfUpdateIsRequired()
    if (isUpdateRequired) {
      this.showUpdateAlert();
    } else {
      // App is up to date
    }
  }

  private showUpdateAlert() {
    this.router.navigateByUrl('/shell');
    this.alertService.showUpdateAlert(this.languageService.getSelectedLanguage(), this.initialUrl)
  }

  private showNoNetworkAlert() {
    this.languageService.getLanguage();
    this.alertService.showNoNetworkAlert(this.languageService.getSelectedLanguage());
  }

  private addKeyboardListeners() {
    window.addEventListener('keyboardWillShow', this.onKeyboardShow);
    window.addEventListener('keyboardWillHide', this.onKeyboardHide);
  }

  private removeKeyboardListeners() {
    window.removeEventListener('keyboardWillShow', this.onKeyboardShow);
    window.removeEventListener('keyboardWillHide', this.onKeyboardHide);
  }

  private onKeyboardShow = (event: any) => {
    setTimeout(() => {
      document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      document.body.style.paddingBottom = `${event.keyboardHeight}px`;
    }, 300); // Adjust timing based on your needs
  }

  private onKeyboardHide = () => {
    document.body.style.paddingBottom = '0px';
  }

  private loadGoogleMapsApi(): void {
    const script = document.createElement('script');
    script.src = GoogleMapsApiUrl;
    script.defer = true;
    script.onload = () => {
      this.googleApiService.setMapsLoaded(true);
    };
    document.head.appendChild(script);
  }

}