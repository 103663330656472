import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private translateService: TranslateService) {}

  selectedLanguage = "en";

  languagesList = [
    {"lang": "fr", "label": "🇫🇷 Français"},
    {"lang": "en", "label": "🇬🇧 English"},
    {"lang": "es", "label": "🇪🇸 Español"},
    {"lang": "it", "label": "🇮🇹 Italiano"},
    {"lang": "de", "label": "🇩🇪 Deutsch"},
    {"lang": "nl", "label": "🇳🇱 Nederlands"}
  ]

  localesList = {
    "fr": "fr-FR",
    "en": "en-GB",
    "es": "es-ES",
    "it": "it-IT",
    "de": "de-DE",
    "nl": "nl-NL"
  };

  getLanguage() {
    const formattedNavigatorLanguage = navigator.language.split("-")[0];
    const defaultLanguage = this.languagesList.some((element) => element.lang === formattedNavigatorLanguage) ? formattedNavigatorLanguage : "en";
    const savedLanguage = localStorage.getItem('language') || defaultLanguage;
  
    this.selectedLanguage = savedLanguage;
    this.updateLanguage(savedLanguage);
  }
  
  changeLanguage(selectedLanguage: string) {
    localStorage.setItem('language', selectedLanguage);
    this.updateLanguage(selectedLanguage);
  }
  
  updateLanguage(language: string) {
    this.translateService.use(language);
  }

  async reloadTranslations() {
    try {
      for (const language of this.languagesList) {
        const translations = await this.translateService.getTranslation(language.lang).toPromise();
        this.translateService.setTranslation(language.lang, translations, true);
      }
      const currentLang = this.getSelectedLanguage() || 'en';
      this.translateService.use(currentLang);
    } catch (error) {
      console.error('Error while loading trads: ', error);
    }
  }

  getSelectedLanguage() {
    return this.selectedLanguage;
  }

  getLocaleLanguage() {
    return this.localesList[this.selectedLanguage] || 'en-GB';
  }

  getLanguagesList() {
    return this.languagesList;
  }
}
