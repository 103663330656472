import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject, from, defer } from 'rxjs';
import { environment } from '@environments/environment';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
  // 1 means can replay only the last value
  isInitialized: ReplaySubject<boolean> = new ReplaySubject(1);

  unloggedHttpOptions = {
    headers: new HttpHeaders({
      'Authorization': ""
    })
  };

  constructor(
    private http: HttpClient,
    private nativeHttp: HTTP,
    private platform: Platform,
  ) {
    this.loadToken();
  }

  async loadToken(): Promise<void> {
    return new Promise(async (resolve) => {
      const token = await localStorage.getItem('token');
      if (token) {
        this.token = token;
        this.test_connection().subscribe(
          (_) => {
            this.isAuthenticated.next(true);
            this.isInitialized.next(true);
            console.log('Complete')
            resolve();
          },
          (_) => {
            this.isAuthenticated.next(false);
            this.isInitialized.next(true);
            console.log('Complete')
            resolve();
          },
        );
      } else {
        this.isAuthenticated.next(false);
        this.isInitialized.next(true);
        resolve();
      }
    });
  }

  register(credentials: {
    email: string;
    password: string;
    phone: string;
    firstName: string;
    lastName: string;
    height: number;
    weight: number;
    address: string;
    waist: number;
    gender: number;
  }): Observable<any> {
    var user = {
      email: credentials.email,
      password: credentials.password,
    };
    var patient = {
      phone: credentials.phone,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      height: credentials.height,
      weight: credentials.weight,
      address: credentials.address,
      waist: credentials.waist,
      gender: credentials.gender,
    };

    var dataRegister = {
      user: user,
      patient: patient,
    };

    console.log(dataRegister);
    return this.http.post(
      `${environment.apiUrl}/patient/register`,
      dataRegister
    );
  }

  login(credentials: {country_code: string, phone: any, password: any}): Observable<any> {
    return this.http
      .post(
        `${environment.apiUrl}/auth/login/`,
        credentials,
        this.unloggedHttpOptions
      )
      .pipe(
        tap((data) => {
          this.isAuthenticated.next(true);
          localStorage.setItem('user_id', data.id);
          localStorage.setItem('refresh_token', data.refresh);
          localStorage.setItem('token', data.access);
          localStorage.setItem('role', data.role_type);
        })
      );
  }

  async logout(callBackend: boolean = true): Promise<Object|null> {
    let http_logout = null;
    if (callBackend) {
      try {
        http_logout = await this.http
          .post(`${environment.apiUrl}/auth/logout/`, { refresh_token: localStorage.getItem('refresh_token') })
          .toPromise();
      } catch (error) {
        // If the access token has expired, it’s refreshed and the request is retried with the new token.
        // This intercepts the initial 401, and even if a 400 is received on the next attempt due to the old token,
        // the retry will succeed.
        try {
          http_logout = await this.http
            .post(`${environment.apiUrl}/auth/logout/`, { refresh_token: localStorage.getItem('refresh_token') })
            .toPromise();
        } catch (error) {
          console.error("Error while logout retry: ", error)
        }
      }
    }
    this.isAuthenticated.next(false);
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    return http_logout;
  }

  async refreshAccessToken(): Promise<void> {
    const refresh_token = localStorage.getItem('refresh_token');
    if (!refresh_token) {
      console.error('No refresh token');
      return;
    }
    if (this.platform.is('hybrid')) {
      // Mode mobile Android and iOS
      try {
        this.nativeHttp.setDataSerializer('json');
        const response = await this.nativeHttp.post(
          `${environment.apiUrl}/auth/login/token-refresh/`,
          { refresh: refresh_token },
          { 'Content-Type': 'application/json' }
        );
        let tokens;
        try {
          tokens = JSON.parse(response.data);
        } catch (parseError) {
          tokens = { response: response.data };
        }
        localStorage.setItem('token', tokens.access);
        localStorage.setItem('refresh_token', tokens.refresh);
      } catch (err) {
        console.error("Error while refreshing token: ", err)
      }
    } else {
      // Mode web
      try {
        const tokens: any = await this.http.post(
          `${environment.apiUrl}/auth/login/token-refresh/`,
          { refresh: refresh_token }
        ).toPromise();
        localStorage.setItem('token', tokens.access);
        localStorage.setItem('refresh_token', tokens.refresh);
      } catch (err) {
        console.error("Error while refreshing token: ", err)
      }
    }
  }

  test_connection() {
    return this.http.get(`${environment.apiUrl}/auth/test/`);
  }
}
