import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

import { USER_ID } from '../myStorageManager';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    }),
  };

  constructor(private http: HttpClient) {}

  getPatientInfos(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/patient/${localStorage.getItem('user_id')}/`,
      this.httpOptions
    );
  }

  modifyPatientInfos(credentials: {
    email;
    password;
    phone;
    firstName;
    lastName;
    height;
    weight;
    address;
    waist;
    gender;
  }): Observable<any> {
    console.log(credentials);
    return this.http
      .patch(
        `${environment.apiUrl}/patient/${localStorage.getItem('user_id')}/`,
        credentials,
        this.httpOptions
      )
      .pipe(
        tap((data) => {
          //If enters here: HTTP Code is 200
        })
      );
  }
}
