//httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { AuthenticationService } from './services/authentification.service';
import { AlertService } from './services/alert.service';

type HttpMethod =
  | 'get'
  | 'post'
  | 'put'
  | 'patch'
  | 'head'
  | 'delete'
  | 'upload'
  | 'download';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  private retried: boolean = false;

  constructor(
    private nativeHttp: HTTP,
    private platform: Platform,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.platform.is('hybrid')) {
      return from(this.handle(request, next, this.retried));
    }
    return from(this.handleNativeRequest(request, this.retried));
  }

  private async handle(request: HttpRequest<any>, next: HttpHandler, retried: boolean): Promise<HttpEvent<any>> {
    try {
      const response = await next.handle(this.updateHeaders(request)).toPromise();
      this.retried = false;
      return response;
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        const hasToken = localStorage.getItem('token');
        if (hasToken) {
          if (!retried) {
            // In case access token is expired
            this.retried = true;
            await this.authenticationService.refreshAccessToken();
            return await this.handle(this.updateHeaders(request), next, this.retried);
          } else {
            // In case refresh token is invalid
            this.authenticationService.logout(false);
            this.retried = false;
          }
        } else {
          // In case user isn't connected and password is wrong
          this.retried = false;
        }
      } else if (error instanceof HttpErrorResponse && error.status === 0) {
        error.error.detail = "SERVER_DOWN";
      }
      return Promise.reject(error);
    }
  }

  private async handleNativeRequest(request: HttpRequest<any>, retried: boolean): Promise<HttpEvent<any>> {
    request = this.updateHeaders(request);

    const headerKeys = request.headers.keys();
    const headers = {};

    headerKeys.forEach((key) => {
      headers[key] = request.headers.get(key);
    });

    try {
      const method = <HttpMethod>request.method.toLowerCase();
      const nativeHttpResponse = await this.nativeHttp.sendRequest(
        request.url,
        {
          method: method,
          data: request.body,
          headers: headers,
          serializer: 'json',
        }
      );

      let body;
      try {
        body = JSON.parse(nativeHttpResponse.data);
      } catch (error) {
        body = { response: nativeHttpResponse.data };
      }

      const response = new HttpResponse({
        body: body,
        status: nativeHttpResponse.status,
        headers: new HttpHeaders(nativeHttpResponse.headers),
        url: nativeHttpResponse.url,
      });
      this.retried = false;
      return Promise.resolve(response);
    } catch (error) {
      if (error.status === 401) {
        const hasToken = localStorage.getItem('token');
        if (hasToken) {
          if (!retried) {
            // In case access token is expired
            this.retried = true;
            await this.authenticationService.refreshAccessToken();
            request = request.clone({
              headers: request.headers.delete('Authorization')
            });
            return this.handleNativeRequest(request, this.retried);
          } else {
            // In case refresh token is invalid
            this.authenticationService.logout(false);
            this.retried = false;
          }
        } else {
          // In case user isn't connected and password is wrong
          this.retried = false;
        }
      } else if (error.status === 0) {
        error.error.detail = "SERVER_DOWN";
      }
      let errorData;
      try {
        errorData = JSON.parse(error.error);
      } catch (e) {
        errorData = error.error;
      }

      const response = new HttpErrorResponse({
        error: errorData,
        status: error.status,
        headers: error.headers,
        url: error.url,
      });
      return Promise.reject(response);
    }
  }

  private updateHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const token = localStorage.getItem('token');
    let headers = {'Accept': 'application/json'};

    //Authentication by setting header with token value
    if (!request.headers.has('Authorization') && token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    if (!request.headers.has('Content-Type')) {
      headers['Content-Type'] = 'application/json';
    }
    return request.clone({setHeaders: headers});
  }

}
