import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { AppLauncher } from '@capacitor/app-launcher';

@Injectable({
  providedIn: 'root'
})

export class AppUpdateService {

  constructor() {}

  async getCurrentAppVersion() {
    const result = await AppUpdate.getAppUpdateInfo();
    if (Capacitor.getPlatform() === 'android') {
      return result.currentVersionCode;
    } else if (Capacitor.getPlatform() === 'ios') {
      return result.currentVersionName;
    } else {
      return null;
    }
  };
  
  async getAvailableAppVersion() {
    const result = await AppUpdate.getAppUpdateInfo();
    if (Capacitor.getPlatform() === 'android') {
      return result.availableVersionCode;
    } else if (Capacitor.getPlatform() === 'ios') {
      return result.availableVersionName;
    } else {
      return null;
    }
  };

  async openAppStore() {
    if (Capacitor.getPlatform() === 'android') {
      await AppUpdate.openAppStore();
    } else if (Capacitor.getPlatform() === 'ios') {
      const appId = '6474441921';
      const appStoreUrl = `https://apps.apple.com/app/id${appId}`;
      await AppLauncher.openUrl({ url: appStoreUrl });
    }
  };

  async performImmediateUpdate() {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.immediateUpdateAllowed) {
      await AppUpdate.performImmediateUpdate();
    }
  };
  
  async startFlexibleUpdate() {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.flexibleUpdateAllowed) {
      await AppUpdate.startFlexibleUpdate();
    }
  };
  
  async completeFlexibleUpdate () {
    await AppUpdate.completeFlexibleUpdate();
  };

}


