import { Component, EventEmitter, Input, Output } from '@angular/core';
import { goBackGeneric } from '@app/utils/utils';

@Component({
  selector: 'app-primary-header',
  templateUrl: './primary-header.component.html',
  styleUrls: ['./primary-header.component.scss'],
})
export class PrimaryHeaderComponent {
  @Input() canGoBack: boolean = false;
  @Input() displayLegalNotice: boolean = false;
  @Output() onGoBack: EventEmitter<any> = new EventEmitter();

  goBack() {
    if (this.onGoBack.observers.length > 0) {
      this.onGoBack.emit([]);
    } else {
      goBackGeneric();
    }
  }
}
